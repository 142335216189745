export const useBaseAnalytics = () => {

  const push = (object) => {
    if (process.client && typeof dataLayer !== 'undefined') {
      dataLayer.push(object);
      //console.log(object);
    }
  }

  const pushEvent = (eventName, object : Object | undefined) => {
    if (object) {
      push(Object.assign({ event: eventName }, Object.assign(object, { _clear: true })));
    }
    else {
      push({ event: eventName, _clear: true });
    }
  }

  return {
    push,
    pushEvent
  };
}