import revive_payload_client_4sVQNw7RlN from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errorHandler_x1Y4n5NP4w from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/plugins/errorHandler.ts";
import gtag_client_Zw8EQXNVTz from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/plugins/gtag.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  errorHandler_x1Y4n5NP4w,
  gtag_client_Zw8EQXNVTz
]